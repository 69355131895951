<template>
  <main>
    <v-container fluid>
      <template>
        <v-breadcrumbs>
          <template>
            <v-breadcrumbs-item>
              <v-icon color="primary">mdi-home</v-icon>
              <v-icon>mdi-chevron-double-right</v-icon> LISTA GEOCAPAS
              <template v-if="mode == 'edit'">
                <v-icon>mdi-chevron-double-right</v-icon> Detalle Geocapa {{ newCase.name }}
              </template>
              <template v-else>
                <v-icon>mdi-chevron-double-right</v-icon> Crear tipo geocapa
              </template>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>
      <v-card class="overflow-hidden">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <span class="green--text">Shape</span>
              <br>

              <v-file-input v-model="loadFile" show-size label="File input" placeholder="Cargue archivo de poligonos"
                outlined dense></v-file-input>
              <!-- <v-file-input
              @change="handleFileUpload($event)"
              placeholder="Cargue archivo de poligonos"
           ></v-file-input> -->

            </v-col>
            <v-col cols="12" sm="12" lg="6" md="6">
              <span class="green--text">Tipo de Área</span>
              <v-autocomplete dense solo placeholder="Seleccione un valor" :items="areasItems"
                :error-messages="validationErrors.role_id" item-text="name" item-value="id" v-model="type_area">
              </v-autocomplete>
            </v-col>
          </v-row>

          <!-- <div class="mt-5" style="height: 500px; width: 100%" id="map">

  </div> -->
        </v-card-text>
      </v-card>
      <div class="text-center mt-2 mb-2">
        <v-btn small class="mx-2" color="primary" rounded @click="save()">
          <v-icon>mdi-content-save</v-icon>
          Guardar
        </v-btn>
        <v-btn color="light" rounded small @click="cancelar">
          CANCELAR
        </v-btn>
      </div>
    </v-container>
  </main>
</template>

<script>
// import { latLng } from "leaflet";
import sweetalert from "sweetalert2";
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
// import { LMap, LTileLayer } from "vue2-leaflet";
import { createGeoJson, updateCategories, getTypeArea } from "@/services/Planning";

//import { typologyByMunicipalitiesMaps } from "../../../../../services/graphicReports/humansServices";


export default {
  components: {
    // LMap,
    // LTileLayer,

    // LMarker,
    // LTooltip,
  },
  props: {
    mode: {
      default: "create"
    }
  },

  data() {
    return {

      newCase: {
        id: "",
        name: "",
        mandate: "",
      },
      allPlanningReference: {},
      reportData: [],
      loadFile: [],
      zoom: 8.5,
      center: [],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      mapOptions: {
        zoomSnap: 0.5,
      },
      areasItems: [],
      type_area: "",
    };
  },
  validations() {
    return {
      newCase: {
        name: { required }
      },
    };
  },

  methods: {

    ...mapActions(["getPlanningReferenceValue"]),
    setEventData(eventData) {
      this.newCase = eventData;
    },
    cancelar() {
      this.$router.push({
        name: "planning.listGeocapas",
      });
    },
    async save() {
      /**
       * [save Funcion que guarda cada una de las  geocapas segun el type de territorio previa validación del contenido de los campos requeridos
        se agregan los valores a enviar en eventRequestData y dependiendo el modo (edit, update) se ejecutara
        el metodo correspondiente (createGeoJson) por ultimo se envia el respectivo mensaje]
       * @param {[this.type_area]} TipoArea [Tipo de area territorio]
      * @param {[this.loadFile]} ArchivoaSubir [Archivo geoJson con la data del teritorio]

       */
      try {
        this.showLoader();
        const eventRequestData = {
          type_area: this.type_area,
          geojson: this.loadFile,
        };
        this.mode === "edit"
          ? await updateCategories(eventRequestData, this.newCase.id)
          : await createGeoJson(eventRequestData);

        let varSuccess = this.mode === "edit"
          ? "actualizado"
          : "creado"
        this.hideLoader();
        await sweetalert.fire(
          `Registro ${varSuccess} correctamente`,
          "",
          "success"
        );

      } catch (err) {
        this.hideLoader();
        console.error(err);

        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud.",
          "error"
        );
      }

    },

  },
  async created() {
    /**
     * [ trae la datacorrespondiente a los tipos de area ]
     * @param {[this.areasItems]} TipoAreas [Tipos de area]
     */
    this.showLoader();
    const eventTypeArea = await getTypeArea();
    this.areasItems = eventTypeArea.data;
    this.hideLoader();
  },

  computed: {
    validationErrors() {
      /**
       * [ Realiza la validacion de nombre  requerido]
       * @param {[name]} Nombre [archivo de referencia GeoJson]
       */
      return {
        name: [
          ...[
            !this.$v.newCase.name.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ]
      };
    },
    ...mapState(["dataSource", "user"]),
  },

};
</script>

<style scoped>

</style>
